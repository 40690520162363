import { RouteRecordRaw } from 'vue-router'

const routerList: Array<RouteRecordRaw> = [
    // 数据表报
    {
        path: '/dataReport',
        name: 'DataReport',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '数据报表',
            enTitle: 'DataReport',
            permissionCode: 'menu:dataReport',
            icon: 'DataAnalysis',
        },
        children: [
            {
                path: 'Dashboard',
                name: 'dashboard',
                component: () => import('../views/dataReport/dashboard/index.vue'),
                meta: {
                    title: '仪表盘',
                    enTitle: 'Dashboard',
                    permissionCode: 'menu:dashboard',
                    operateCodeList: [],
                },
            },
            {
                path: 'dataStatistics',
                name: 'DataStatistics',
                component: () => import('../views/dataReport/dataStatistics/index.vue'),
                meta: {
                    title: '数字化大屏',
                    enTitle: 'dataStatistics',
                    permissionCode: 'menu:dataStatistics',
                    operateCodeList: [],
                },
            },
        ],
    },
    // 资产管理
    {
        path: '/assetManagement',
        name: 'assetManagement',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '资产管理',
            enTitle: 'assetManagement',
            icon: 'Operation',
            permissionCode: 'menu:assetManagement',
        },
        children: [
            {
                path: 'assetManagementList',
                name: 'assetManagementList',
                component: () => import('../views/assetManagement/assetManagementList/index.vue'),
                meta: {
                    title: '资产管理',
                    enTitle: 'Asset Management',
                    permissionCode: 'menu:assetManagementList',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'assetManagementList:add' },
                        { name: '详情', enName: 'detail', code: 'assetManagementList:detail' },
                        { name: '编辑', enName: 'Edit', code: 'assetManagementList:edit' },
                        { name: '删除', enName: 'Delete', code: 'assetManagementList:delete' },
                        { name: '资产移交', enName: 'transfer', code: 'assetManagementList:transfer' },
                        { name: '资产导入', enName: 'download', code: 'assetManagementList:download' },
                        { name: '报废', enName: 'scrap', code: 'assetManagementList:scrap' },
                        { name: '批量领用', enName: 'requisition', code: 'assetManagementList:batchRequisition' },
                        { name: '批量归还', enName: 'return', code: 'assetManagementList:batchReturn' },
                        { name: '批量报废', enName: 'BatchScrapping', code: 'assetManagementList:batchScrap' },
                        { name: '批量移交', enName: 'BatchHandover', code: 'assetManagementList:batchTransfer' },
                    ],
                },
            },
        ],
    },
    //物模型列表
    {
        path: '/ModelManagement',
        name: 'modelManagement',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '物模型管理',
            enTitle: 'ObjectModelManagement',
            icon: 'Operation',
            permissionCode: 'menu:modelManagement',
        },
        children: [
            {
                path: 'category',
                name: 'category',
                component: () => import('../views/ModelManagement/category/index.vue'),
                meta: {
                    title: '种类管理',
                    enTitle: 'Category Management',
                    permissionCode: 'menu:categorys',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'categorys:add' },
                        { name: '编辑', enName: 'Edit', code: 'categorys:edit' },
                        { name: '删除', enName: 'Delete', code: 'categorys:delete' },
                    ],
                },
            },
            {
                path: 'modelManagementList',
                name: 'modelManagementList',
                component: () => import('../views/ModelManagement/ModelManagementList/index.vue'),
                meta: {
                    title: '物模型列表',
                    enTitle: 'Object Model Management',
                    permissionCode: 'menu:modelManagementList',
                    operateCodeList: [
                        { name: '新增物模型', enName: 'Create', code: 'modelManagementList:add' },
                        { name: '编辑', enName: 'Edit', code: 'modelManagementList:edit' },
                        { name: '删除', enName: 'Delete', code: 'modelManagementList:delete' },
                        { name: '导入模板', enName: 'import Model', code: 'modelManagementList:importing' },
                    ],
                },
            },
            {
                path: 'equipmentManagement',
                name: 'equipmentManagement',
                component: () => import('../views/ModelManagement/EquipmentManagementList/index.vue'),
                meta: {
                    title: '型号列表',
                    enTitle: 'Equipment model and object model management',
                    permissionCode: 'menu:equipmentManagementList',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'equipmentManagementList:add' },
                        { name: '变更状态', enName: 'Change Status', code: 'equipmentManagementList:change' },
                        { name: '编辑', enName: 'edit', code: 'equipmentManagementList:edit' },
                        { name: '删除', enName: 'delete', code: 'equipmentManagementList:delete' },
                        {
                            name: '物模型配置',
                            enName: 'Object Model Configuration',
                            code: 'equipmentManagementList:physicalModel',
                        },
                        {
                            name: '能耗配置',
                            enName: 'Energy consumption configuration',
                            code: 'equipmentManagementList:configuration',
                        },
                    ],
                },
            },
        ],
    },
    // 项目管理
    {
        path: '/projectManager',
        name: 'ProjectManager',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '项目管理',
            enTitle: 'ProjectManager',
            icon: 'Histogram',
            permissionCode: 'menu:projectManager',
        },
        children: [
            {
                path: 'projectType',
                name: 'ProjectType',
                component: () => import('../views/projectManager/projectType/index.vue'),
                meta: {
                    title: '项目类型',
                    enTitle: 'ProjectType',
                    permissionCode: 'menu:projectType',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'projectType:add' },
                        { name: '变更状态', enName: 'Change Status', code: 'projectType:change' },
                        { name: '编辑', enName: 'Edit', code: 'projectType:edit' },
                        { name: '删除', enName: 'Delete', code: 'projectType:delete' },
                    ],
                },
            },
            {
                path: 'projectList',
                name: 'ProjectList',
                component: () => import('../views/projectManager/projectList/index.vue'),
                meta: {
                    title: '项目列表',
                    enTitle: 'ProjectList',
                    permissionCode: 'menu:projectList',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'projectList:add' },
                        { name: '变更状态', enName: 'Change Status', code: 'projectList:change' },
                        { name: '编辑', enName: 'Edit', code: 'projectList:edit' },
                        { name: '查看', enName: 'Detail', code: 'projectList:view' },
                        { name: '删除', enName: 'Delete', code: 'projectList:delete' },
                    ],
                },
            },
            {
                path: 'editProject',
                name: 'editProject',
                component: () => import('../views/projectManager/projectList/editForm_layout.vue'),
                meta: {
                    title: '新建项目',
                    enTitle: 'ProjectList',
                    permissionCode: 'menu:projectList',
                    hidden: true,
                },
            },
        ],
    },
    // 网关管理
    {
        path: '/gatewayManager',
        name: 'GatewayManager',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '网关管理',
            enTitle: 'GatewayManager',
            icon: 'Monitor',
            permissionCode: 'menu:gatewayManager',
        },
        children: [
            {
                path: 'gatewayType',
                name: 'GatewayType',
                component: () => import('../views/gatewayManager/gatewayType/index.vue'),
                meta: {
                    title: '网关类型',
                    enTitle: 'GatewayType',
                    permissionCode: 'menu:gatewayType',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'gatewayType:add' },
                        { name: '编辑', enName: 'Edit', code: 'gatewayType:edit' },
                        { name: '变更状态', enName: 'Change Status', code: 'gatewayType:change' },
                        { name: '删除', enName: 'Delete', code: 'gatewayType:delete' },
                        { name: '查看', enName: 'Detail', code: 'gatewayType:view' },
                    ],
                },
            },
            {
                path: 'gatewayDevice',
                name: 'GatewayDevice',
                component: () => import('../views/gatewayManager/gatewayDevice/index.vue'),
                meta: {
                    title: '网关设备',
                    enTitle: 'GatewayDevice',
                    permissionCode: 'menu:gatewayDevice',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'gatewayDevice:add' },
                        { name: '编辑', enName: 'Edit', code: 'gatewayDevice:edit' },
                        { name: '删除', enName: 'Delete', code: 'gatewayDevice:delete' },
                        { name: '查看', enName: 'Detail', code: 'gatewayDevice:view' },
                        { name: '控制面板', enName: 'Control Panel', code: 'gatewayDevice:setting' },
                        { name: '档案下发', enName: 'File Distribution', code: 'gatewayDevice:file' },
                        { name: '网关信息', enName: 'Gateway Info', code: 'gatewayDevice:info' },
                    ],
                },
            },
            {
                path: 'gatewayGroup',
                name: 'GatewayGroup',
                component: () => import('../views/gatewayManager/gatewayGroup/index.vue'),
                meta: {
                    title: '网关分组',
                    enTitle: 'GatewayGroup',
                    permissionCode: 'menu:gatewayGroup',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'gatewayGroup:add' },
                        { name: '编辑', enName: 'Edit', code: 'gatewayGroup:edit' },
                        { name: '删除', enName: 'Delete', code: 'gatewayGroup:delete' },
                        { name: '控制面板', enName: 'Control Panel', code: 'gatewayGroup:setting' },
                        { name: '档案下发', enName: 'File Distribution', code: 'gatewayGroup:file' },
                    ],
                },
            },
        ],
    },
    // 节点管理
    {
        path: '/nodeManager',
        name: 'NodeManager',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '设备管理',
            enTitle: 'NodeManager',
            icon: 'Cpu',
            permissionCode: 'menu:nodeManager',
        },
        children: [
            {
                path: 'nodeType',
                name: 'NodeType',
                component: () => import('../views/nodeManager/nodeType/index.vue'),
                meta: {
                    title: '节点类型',
                    enTitle: 'NodeType',
                    permissionCode: 'menu:nodeType',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'nodeType:add' },
                        { name: '编辑', enName: 'Edit', code: 'nodeType:edit' },
                        { name: '变更状态', enName: 'Change Status', code: 'nodeType:change' },
                        { name: '删除', enName: 'Delete', code: 'nodeType:delete' },
                        { name: '查看', enName: 'Detail', code: 'nodeType:view' },
                    ],
                },
            },
            {
                path: 'nodeDevice',
                name: 'NodeDevice',
                component: () => import('../views/nodeManager/nodeDevice/index.vue'),
                meta: {
                    title: '设备列表',
                    enTitle: 'NodeList',
                    permissionCode: 'menu:nodeDevice',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'nodeDevice:add' },
                        { name: '下载导入模板', enName: 'Download Importing Templates', code: 'nodeDevice:download' },
                        { name: '导入节点设备', enName: 'Importing Node Device', code: 'nodeDevice:importing' },
                        { name: '编辑', enName: 'Edit', code: 'nodeDevice:edit' },
                        { name: '删除', enName: 'Delete', code: 'nodeDevice:delete' },
                        { name: '查看', enName: 'Detail', code: 'nodeDevice:view' },
                        { name: '控制面板', enName: 'Control Panel', code: 'nodeDevice:setting' },
                        { name: '节点信息', enName: 'Node Info', code: 'nodeDevice:info' },
                        { name: '档案下发', enName: 'File Distribution', code: 'nodeDevice:file' },
                        { name: '采集配置', enName: 'acquisitionConfig', code: 'nodeDevice:acquisitionConfig' },
                        { name: '停电', enName: 'cutPower', code: 'nodeDevice:cutPower' },
                        { name: '复电', enName: 'restorePower', code: 'nodeDevice:restorePower' },
                        { name: '抄表', enName: 'readMeter', code: 'nodeDevice:readMeter' },
                        // { name: '报修', enName: 'addRepairReport', code: 'nodeDevice:addRepairReport' },
                        { name: '绑定租户', enName: 'bindRenter', code: 'nodeDevice:bindRenter' },
                        { name: '解绑租户', enName: 'unbindRenter', code: 'nodeDevice:unbindRenter' },
                        { name: '退费', enName: 'refundDevice', code: 'nodeDevice:refundDevice' },
                    ],
                },
            },
            {
                path: 'infoPage',
                name: 'InfoPage',
                component: () => import('../components/info.vue'),
                meta: {
                    title: '节点信息',
                    enTitle: 'infoPage',
                    hidden: true,
                },
            },
            {
                path: 'nodeGroup',
                name: 'NodeGroup',
                component: () => import('../views/nodeManager/nodeGroup/index.vue'),
                meta: {
                    title: '功能分组',
                    enTitle: 'NodeGroup',
                    permissionCode: 'menu:nodeGroup',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'nodeGroup:add' },
                        { name: '编辑', enName: 'Edit', code: 'nodeGroup:edit' },
                        { name: '删除', enName: 'Delete', code: 'nodeGroup:delete' },
                        { name: '控制面板', enName: 'Control Panel', code: 'nodeGroup:setting' },
                        { name: '告警策略下发', enName: 'Alarm Distribution', code: 'nodeGroup:alarm' },
                        { name: '档案下发', enName: 'File Distribution', code: 'nodeGroup:file' },
                    ],
                },
            },
            {
                path: 'projectTag',
                name: 'ProjectTag',
                component: () => import('../views/nodeManager/projectTag/index.vue'),
                meta: {
                    title: '位置分组',
                    enTitle: 'ProjectTag',
                    // permissionCode: 'menu:projectTag',
                    // operateCodeList: [
                    //     { name: '新增', enName: 'Create', code: 'projectTag:add' },
                    //     { name: '编辑', enName: 'Edit', code: 'projectTag:edit' },
                    //     { name: '查看', enName: 'Detail', code: 'projectTag:view' },
                    //     { name: '删除', enName: 'Delete', code: 'projectTag:delete' },
                    // ],
                },
            },
            {
                path: 'assetsRecords',
                name: 'assetsRecords',
                component: () => import('../views/assetsRecords/index.vue'),
                meta: {
                    title: '领还记录',
                    enTitle: 'assetsRecords',
                    permissionCode: 'menu:assetsRecords',
                    operateCodeList: [
                        { name: '新建领用', enName: 'createAcquistion', code: 'assetsRecords:createAcquistion' },
                        { name: '新建归还', enName: 'createReturn', code: 'assetsRecords:createReturn' },
                        { name: '编辑', enName: 'Edit', code: 'assetsRecords:edit' },
                        { name: '删除', enName: 'Delete', code: 'assetsRecords:delete' },
                        { name: '归还', enName: 'Return', code: 'assetsRecords:Return' },
                    ],
                },
            },
            {
                path: 'assetsRecordsEdit',
                name: 'assetsRecordsEdit',
                component: () => import('../views/assetsRecords/editForm.vue'),
                meta: {
                    title: '领还记录',
                    enTitle: 'assetsRecordsEdit',
                    hidden: true,
                },
            },
        ],
    },
    // 智能控制
    {
        path: '/intelligentControl',
        name: 'IntelligentControl',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '智能控制',
            enTitle: 'IntelligentControl',
            icon: 'Operation',
            permissionCode: 'menu:intelligentControl',
        },
        children: [
            {
                path: 'controlManager',
                name: 'ControlManager',
                component: () => import('../views/intelligentControl/controlManager/index.vue'),
                meta: {
                    title: '控制管理',
                    enTitle: 'ControlManager',
                    permissionCode: 'menu:controlManager',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'controlManager:add' },
                        { name: '删除', enName: 'Delete', code: 'controlManager:delete' },
                        { name: '查看', enName: 'Detail', code: 'controlManager:view' },
                        { name: '下发', enName: 'Distribution', code: 'controlManager:distribution' },
                    ],
                },
            },
            {
                path: 'strategyManager',
                name: 'StrategyManager',
                component: () => import('../views/intelligentControl/strategyManager/index.vue'),
                meta: {
                    title: '策略管理',
                    enTitle: 'StrategyManager',
                    permissionCode: 'menu:strategyManager',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'strategyManager:add' },
                        { name: '编辑', enName: 'Edit', code: 'strategyManager:edit' },
                        { name: '查看', enName: 'Detail', code: 'strategyManager:view' },
                    ],
                },
            },
            {
                path: 'editStrategy',
                name: 'editStrategy',
                component: () => import('../views/intelligentControl/strategyManager/editForm.vue'),
                meta: {
                    title: '新建策略',
                    enTitle: 'editStrategy',
                    hidden: true,
                },
            },
        ],
    },
    // 智能告警
    {
        path: '/intelligentAlarm',
        name: 'IntelligentAlarm',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '智能告警',
            enTitle: 'IntelligentAlarm',
            icon: 'Notification',
            permissionCode: 'menu:intelligentAlarm',
        },
        children: [
            {
                path: 'alarmStrategy',
                name: 'AlarmStrategy',
                component: () => import('../views/intelligentAlarm/alarmStrategy/index.vue'),
                meta: {
                    title: '告警策略',
                    enTitle: 'AlarmStrategy',
                    permissionCode: 'menu:alarmStrategy',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'alarmStrategy:add' },
                        { name: '编辑', enName: 'Edit', code: 'alarmStrategy:edit' },
                        { name: '删除', enName: 'Delete', code: 'alarmStrategy:delete' },
                    ],
                },
            },
            {
                path: 'alarmList',
                name: 'AlarmList',
                component: () => import('../views/intelligentAlarm/alarmList/index.vue'),
                meta: {
                    title: '告警列表',
                    enTitle: 'alarmList',
                    permissionCode: 'menu:alarmList',
                    operateCodeList: [{ name: '变更状态', enName: 'Change Status', code: 'alarmList:change' }],
                },
            },
        ],
    },
    // 升级管理
    {
        path: '/updateManager',
        name: 'UpdateManager',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '升级管理',
            enTitle: 'UpdateManager',
            icon: 'Notification',
            permissionCode: 'menu:updateManager',
        },
        children: [
            {
                path: 'versionManager',
                name: 'VersionManager',
                component: () => import('../views/updateManager/versionManager/index.vue'),
                meta: {
                    title: '版本管理',
                    enTitle: 'VersionManager',
                    permissionCode: 'menu:versionManager',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'versionManager:add' },
                        { name: '下发', enName: 'Distribution', code: 'versionManager:distribution' },
                        { name: '编辑', enName: 'Edit', code: 'versionManager:edit' },
                        { name: '删除', enName: 'Delete', code: 'versionManager:delete' },
                        // { name: '查看', enName: 'Detail', code: 'versionManager:view' },
                        { name: '下载', enName: 'Download', code: 'versionManager:download' },
                    ],
                },
            },
        ],
    },
    // 用户管理
    {
        path: '/userManager',
        name: 'UserManager',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '用户管理',
            enTitle: 'UserManager',
            permissionCode: 'menu:userManager',
            icon: 'User',
        },
        children: [
            {
                path: 'tenantManager',
                name: 'TenantManager',
                component: () => import('../views/userManager/tenantManager/index.vue'),
                meta: {
                    title: '运营商管理',
                    enTitle: 'TenantManager',
                    permissionCode: 'menu:tenantManager',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'tenantManager:add' },
                        { name: '变更状态', enName: 'Change Status', code: 'tenantManager:change' },
                        { name: '编辑', enName: 'Edit', code: 'tenantManager:edit' },
                        { name: '查看', enName: 'Detail', code: 'tenantManager:view' },
                        { name: '重置密码', enName: 'Reset Password', code: 'tenantManager:reset' },
                    ],
                },
            },
            {
                path: 'roleManager',
                name: 'RoleManager',
                component: () => import('../views/userManager/roleManager/index.vue'),
                meta: {
                    title: '角色管理',
                    enTitle: 'RoleManager',
                    permissionCode: 'menu:roleManager',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'roleManager:add' },
                        { name: '变更状态', enName: 'Change Status', code: 'roleManager:change' },
                        { name: '编辑', enName: 'Edit', code: 'roleManager:edit' },
                        { name: '权限设置', enName: 'Permission Setting', code: 'roleManager:permission' },
                    ],
                },
            },
            // {
            //     path: 'roleManager1',
            //     name: 'RoleManager1',
            //     component: () => import('../views/userManager/roleManager/index1.vue'),
            //     meta: {
            //         title: '树形',
            //         enTitle: 'RoleManager1',
            //     },
            // },
            {
                path: 'accountManager',
                name: 'AccountManager',
                component: () => import('../views/userManager/accountManager/index.vue'),
                meta: {
                    title: '账号管理',
                    enTitle: 'AccountManager',
                    permissionCode: 'menu:accountManager',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'accountManager:add' },
                        { name: '变更状态', enName: 'Change Status', code: 'accountManager:change' },
                        { name: '编辑', enName: 'Edit', code: 'accountManager:edit' },
                        { name: '查看', enName: 'Detail', code: 'accountManager:view' },
                        { name: '重置密码', enName: 'Reset Password', code: 'accountManager:reset' },
                        { name: '删除', enName: 'Reset Password', code: 'accountManager:delete' },
                    ],
                },
            },
        ],
    },
    // 售后管理
    {
        path: '/afterSales',
        name: 'AfterSales',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '售后管理',
            enTitle: 'afterSales',
            icon: 'document-remove',
            permissionCode: 'menu:afterSales',
        },
        children: [
            {
                path: 'feedback',
                name: 'Feedback',
                component: () => import('../views/feedback/index.vue'),
                meta: {
                    title: '问题反馈',
                    enTitle: 'Feedback',
                    permissionCode: 'menu:feedback',
                },
            },
        ],
    },
    // 租户管理
    {
        path: '/renter',
        name: 'Renter',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '租户管理',
            enTitle: 'renter',
            icon: 'User',
            permissionCode: 'menu:renter',
        },
        children: [
            // 租户
            {
                path: 'renters',
                name: 'Renters',
                component: () => import('../views/renter/index.vue'),
                meta: {
                    title: '租户列表',
                    enTitle: 'Renters',
                    permissionCode: 'menu:renters',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'renters:add' },
                        { name: '编辑', enName: 'Edit', code: 'renters:edit' },
                        // { name: '删除', enName: 'Delete', code: 'renters:delete' },
                        { name: '设备列表', enName: 'List', code: 'renters:list' },
                    ],
                },
            },
        ],
    },
    // 商户管理
    {
        path: '/trader',
        name: 'Trader',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '商户管理',
            enTitle: 'trader',
            icon: 'tickets',
            permissionCode: 'menu:trader',
        },
        children: [
            // 商户
            {
                path: 'traders',
                name: 'Traders',
                component: () => import('../views/trader/index.vue'),
                meta: {
                    title: '商户列表',
                    enTitle: 'Traders',
                    permissionCode: 'menu:traders',
                    operateCodeList: [
                        { name: '新增', enName: 'Create', code: 'traders:add' },
                        { name: '编辑', enName: 'Edit', code: 'traders:edit' },
                        { name: '删除', enName: 'Delete', code: 'traders:delete' },
                    ],
                },
            },
        ],
    },
    // 订单管理
    {
        path: '/order',
        name: 'Order',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '订单管理',
            enTitle: 'order',
            icon: 'menu',
            permissionCode: 'menu:order',
        },
        children: [
            // 退款记录
            // {
            //     path: 'refundRecords',
            //     name: 'RefundRecords',
            //     component: () => import('../views/order/refundRecords.vue'),
            //     meta: {
            //         title: '退款订单',
            //         enTitle: 'RefundRecords',
            //         // permissionCode: 'menu:refundRecords',
            //     },
            // },
            // 充值记录
            {
                path: 'rechargeRecords',
                name: 'RechargeRecords',
                component: () => import('../views/order/rechargeRecords.vue'),
                meta: {
                    title: '充值订单',
                    enTitle: 'RechargeRecords',
                    permissionCode: 'menu:rechargeRecords',
                    operateCodeList: [{ name: '审核', enName: 'examine', code: 'rechargeRecords:examine' }],
                },
            },
            // 消费记录
            {
                path: 'feeRecords',
                name: 'FeeRecords',
                component: () => import('../views/order/feeRecords.vue'),
                meta: {
                    title: '消费订单',
                    enTitle: 'FeeRecords',
                    permissionCode: 'menu:feeRecords',
                },
            },
            // 对账
            {
                path: 'accountStatement',
                name: 'AccountStatement',
                component: () => import('../views/order/accountStatement.vue'),
                meta: {
                    title: '对账',
                    enTitle: 'AccountStatement',
                    permissionCode: 'menu:accountStatement',
                },
            },
        ],
    },
    // 电表
    {
        path: '/meter',
        name: 'meter',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '电表数据',
            enTitle: 'Meter Data',
            icon: 'User',
            hidden: true,
        },
        children: [
            {
                path: '/meter/data',
                name: 'meterData',
                component: () => import('../views/meter/meterData/index.vue'),
                meta: {
                    title: '电表数据',
                    enTitle: 'meter detail',
                    // permissionCode: 'menu:tenantManager',
                    // operateCodeList: [
                    //     { name: '新增', enName: 'Create', code: 'tenantManager:add' },
                    //     { name: '变更状态', enName: 'Change Status', code: 'tenantManager:change' },
                    //     { name: '编辑', enName: 'Edit', code: 'tenantManager:edit' },
                    //     { name: '查看', enName: 'Detail', code: 'tenantManager:view' },
                    //     { name: '重置密码', enName: 'Reset Password', code: 'tenantManager:reset' },
                    // ],
                },
            },
            {
                path: '/meter/detail',
                name: 'meterDetail',
                component: () => import('../views/meter/meterDetail/index.vue'),
                meta: {
                    title: '电表详情',
                    enTitle: 'meter detail',
                    // permissionCode: 'menu:tenantManager',
                    // operateCodeList: [
                    //     { name: '新增', enName: 'Create', code: 'tenantManager:add' },
                    //     { name: '变更状态', enName: 'Change Status', code: 'tenantManager:change' },
                    //     { name: '编辑', enName: 'Edit', code: 'tenantManager:edit' },
                    //     { name: '查看', enName: 'Detail', code: 'tenantManager:view' },
                    //     { name: '重置密码', enName: 'Reset Password', code: 'tenantManager:reset' },
                    // ],
                },
            },
        ],
    },
]
export default routerList
