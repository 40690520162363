import { createStore } from 'vuex'
import Api from '../utils/api'
import Axios from '../utils/request'
import userManager from '@/store/modules/userManager'
import projectManager from '@/store/modules/projectManager'
import gatewayManager from '@/store/modules/gatewayManager'
import nodeManager from '@/store/modules/nodeManager'
import intelligentAlarm from '@/store/modules/intelligentAlarm'
import intelligentControl from '@/store/modules/intelligentControl'
import updateManager from '@/store/modules/updateManager'
import afterSales from '@/store/modules/afterSales'
import category from '@/store/modules/category'
import dataReport from '@/store/modules/dataReport'
import assetManage from '@/store/modules/assetManage'
import mode from '@/store/modules/mode'
import routerList from '@/router/routerList'
import { handleMenu } from '@/utils'
import { findElementInArray } from '@/utils/utils'

export default createStore({
    state: {
        userInfo: {},
        lang: '',
        dict: {},
    },
    getters: {
        // 根据用户权限，生成菜单
        menuList(state: any) {
            return handleMenu(routerList, state.userInfo.menusAuthCodes)
        },
        // 下拉选项构建
        dictOptions: (state: any) => (dictType: string) => {
            const dicts = state.dict[dictType]
            const dictList: { label: any; value: any }[] = []
            if (dicts) {
                if (dictType == 'DEVICE_CATEGORY') {
                    dicts.forEach((item: { categoryName: any; deviceCategory: any }) => {
                        dictList.push({
                            label: item.categoryName,
                            value: item.deviceCategory,
                        })
                    })
                } else {
                    dicts.forEach((item: { dictValue: any; dictCode: any }) => {
                        dictList.push({
                            label: item.dictValue,
                            value: item.dictCode,
                        })
                    })
                }
            }
            return dictList
        },
        getDict: (state: any) => (dictType: string, dictValue: string) => {
            const dicts = state.dict[dictType]
            let dict = null as any
            if (dicts) {
                dict = findElementInArray(dicts, dictValue, 'dictCode')
            }
            return dict
        },
    },
    mutations: {
        setUserInfo(state, payload) {
            state.userInfo = payload
        },
        setLang(state, payload) {
            state.lang = payload
        },
        setDict(state, payload) {
            state.dict[payload.dictType] = payload.value
        },
    },
    actions: {
        login(context, params) {
            return Axios.post(Api.userManager.post.login, params)
        },
        getImageCaptcha(context, params) {
            return Axios.get(Api.userManager.get.getImageCaptcha, params)
        },
        upload(context, params) {
            return Axios.post(Api.common.post.upload, params)
        },
        traderUpload(context, params) {
            return Axios.post(Api.common.post.traderUpload, params)
        },
        getMessageCaptcha(context, params) {
            return Axios.get(Api.common.get.getMessageCaptcha, { params })
        },
        async getUserAuth(context, params) {
            try {
                const resAuth: any = await Axios.get(Api.userManager.get.getUserAuth, { params })
                context.commit('setUserInfo', resAuth.data)
                return Promise.resolve(resAuth)
            } catch (e) {
                return Promise.reject(e)
            }
        },
        // 执行网关、网关组设备命令
        pushBroadcastCommand(context, params) {
            return Axios.post(Api.common.post.pushBroadcastCommand, params)
        },
        // 执行节点设备命令
        pushCommand(context, params) {
            return Axios.post(Api.common.post.pushCommand, params)
        },
        // 执行节点设备命令
        pushGroupCommand(context, params) {
            return Axios.post(Api.common.post.pushGroupCommand, params)
        },
        // 下发设备档案
        sendDocument(context, params) {
            return Axios.post(Api.common.post.sendDocument, params)
        },
        // 获取设备属性
        getDeviceState(context, params) {
            return Axios.get(Api.common.get.getDeviceState, { params })
        },
        // 获取设备设置属性记录
        getPropertySettingRecords(context, params) {
            return Axios.get(Api.common.get.getPropertySettingRecords, { params })
        },
        // 获取设备模型
        getDeviceModelPage(context, params) {
            return Axios.post(Api.common.post.getDeviceModelPage, params)
        },
        //个人重设子账号密码
        resetPasswordBySelf(context, params) {
            return Axios.post(Api.common.post.resetPasswordBySelf, params)
        },
        // 获取字典信息
        getDict(context, params) {
            Axios.get(Api.dict.get.getDictByDictType, { params }).then(res => {
                if (res.data) {
                    context.commit('setDict', {
                        dictType: params.dictType,
                        value: res.data,
                    })
                }
            })
        },
        // 获取字典信息
        getDict1(context, params) {
            Axios.post(Api.dict.post.getCategoryLists, { params }).then(res => {
                if (res.data) {
                    console.log('1231', res.data)
                    context.commit('setDict', {
                        dictType: 'DEVICE_CATEGORY',
                        value: res.data,
                    })
                }
            })
        },
        // 刷新网关节点
        refreshGatewayNode(context, params) {
            return Axios.get(Api.nodeManager.get.getOnlineNodes, { params })
        },
    },
    modules: {
        userManager,
        projectManager,
        gatewayManager,
        nodeManager,
        intelligentAlarm,
        intelligentControl,
        updateManager,
        afterSales,
        category,
        dataReport,
        assetManage,
        mode,
    },
})
