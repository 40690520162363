import Api from '../../utils/api'
import Axios from '../../utils/request'

export default {
    namespace: 'true',
    state: {},
    mutations: {},
    actions: {
        // 获取节点类型列表
        getNodeTypePage(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.getNodeTypePage, params)
        },
        // 获取物模型列表
        getDeviceProperties(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.getDeviceProperties, params)
        },
        // 修改节点类型状态
        changeNodeTypeStatus(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.changeNodeTypeStatus, params)
        },
        // 删除节点类型
        removeNodeType(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.removeNodeType, params)
        },
        // 新增节点类型
        addNodeType(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.addNodeType, params)
        },
        // 编辑节点类型
        editNodeType(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.editNodeType, params)
        },
        // 获取节点类型详情
        getNodeTypeById(context: any, params: any) {
            return Axios.get(Api.nodeManager.get.getNodeTypeById, { params })
        },
        // 获取节点列表
        getNodePage(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.getNodePage, params)
        },
        // 获取所属策略列表
        getStrategyListByNodeId(context: any, params: any) {
            return Axios.get(Api.nodeManager.get.getStrategyListByNodeId, { params })
        },
        // 根据id获取节点详情New
        getByIdNew(context: any, params: any) {
            return Axios.get(Api.nodeManager.get.getByIdNew, { params })
        },
        // 节点刷新
        getStateNew(context: any, params: any) {
            return Axios.get(Api.nodeManager.get.getStateNew, { params })
        },
        // 分页查询可分组节点
        getNodesPage(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.getNodesPage, params)
        },
        // 新增节点设备
        addNode(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.addNode, params)
        },
        // 编辑节点设备
        editNode(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.editNode, params)
        },
        // 删除节点设备
        removeNode(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.removeNode, params)
        },
        // 获取节点详情
        getNodeById(context: any, params: any) {
            return Axios.get(Api.nodeManager.get.getNodeById, { params })
        },
        // 获取节点分组列表
        getNodeGroupPage(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.getNodeGroupPage, params)
        },
        // 删除节点分组
        removeNodeGroup(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.removeNodeGroup, params)
        },
        // 分组下发
        sendGroup(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.sendGroup, params)
        },
        // 新增节点分组
        addNodeGroup(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.addNodeGroup, params)
        },
        // 编辑
        editNodeGroup(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.editNodeGroup, params)
        },
        // 获取节点分组详情
        getNodeGroupById(context: any, params: any) {
            return Axios.get(Api.nodeManager.get.getNodeGroupById, { params })
        },
        // 上传节点设备文件
        importNodeDevices(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.importNodeDevices, params)
        },
        // 批量删除节点
        batchRemoveNode(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.batchRemoveNode, params)
        },
        // 绑定节点
        changeBindMode(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.changeBindMode, params)
        },
        getBindMode(context: any, params: any) {
            return Axios.get(Api.nodeManager.get.getBindMode, { params })
        },
        // 停电
        cutPower(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.cutPower, params)
        },
        // 复电
        restorePower(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.restorePower, params)
        },
        // 抄表
        readMeter(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.readMeter, params)
        },
        // 报修
        addRepairReport(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.addRepairReport, params)
        },
        // 绑定租户
        bindRenter(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.bindRenter, params)
        },
        // 解绑租户
        unbindRenter(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.unbindRenter, params)
        },
        // 获取可绑定的设备
        getCanBindDevices(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.getCanBindDevices, params)
        },
        // 分页查询租户
        pageRenters(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.pageRenters, params)
        },
        // 新增租户
        addRenter(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.addRenter, params)
        },
        // 编辑租户
        editRenter(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.editRenter, params)
        },
        // 获取设备列表(余额)
        getRenterDevices(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.getRenterDevices, params)
        },
        // 分页查询商户
        pageTraders(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.pageTraders, params)
        },
        // 添加商户
        addTrader(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.addTrader, params)
        },
        // 编辑商户
        editTrader(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.editTrader, params)
        },
        // 删除商户
        removeTrader(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.removeTrader, params)
        },
        // 获取消费记录
        pageFeeRecords(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.pageFeeRecords, params)
        },
        // 获取充值记录
        getRechargeRecords(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.getRechargeRecords, params)
        },
        // 获取退款记录
        getRefundRecords(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.getRefundRecords, params)
        },
        // 手动充值审核
        rechargeReview(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.rechargeReview, params)
        },
        // 手动充值
        rechargeByHand(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.rechargeByHand, params)
        },
        // 绑定设备
        bindDevice(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.bindDevice, params)
        },
        // 解绑设备
        unbindDevice(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.unbindDevice, params)
        },
        // 退费
        refundDevice(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.refundDevice, params)
        },
        // 对账单
        getStatementAcc(context: any, params: any) {
            return Axios.post(Api.nodeManager.post.getStatementAcc, params)
        },
    },
    getters: {},
}
